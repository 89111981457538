"use server";

import { redirect } from "@solidjs/router";
import { getGiftById, getShareableGiftLink } from "../apis/server_apis";
import { GiftCardDetails } from "../types/pluto";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { APIError } from "~/utils/fetch";

export type PlutoGiftDetailsRouteData = {
  giftDetails?: GiftCardDetails;
  shareableLink: string | null;
};

export const getPlutoGiftDetailsRouteData = async (
  giftId: string
): Promise<PlutoGiftDetailsRouteData> => {
  let giftDetails: GiftCardDetails | undefined;
  let shareableLink: string | null = null;

  let sid = getCookie(Cookie.SessionId);

  if (!sid) {
    throw redirect("/pluto/login");
  }

  try {
    giftDetails = await getGiftById(giftId, {
      throwAuthError: true,
    });
    let paymentStatus = giftDetails.paymentStatus;
    if (paymentStatus === "PAID") {
      let response = await getShareableGiftLink(giftId);
      shareableLink = response.link;
    }
  } catch (error) {
    if (error instanceof APIError && error.code === 401) {
      throw redirect("/pluto/login");
    }
    throw error;
  }

  return { giftDetails, shareableLink };
};
